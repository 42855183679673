import { defineStore } from "pinia";

export const useUser = defineStore("user", () => {
  const dict = useDictionaries();

  const {
    data: user,
    refresh,
    status,
  } = useAPI("/users/me/", {
    transform: (source) => enrichUser(enrich(source, dict.dictionaries)),
  });

  // action: join or leave party
  const doParticipation = async (opportunityId, accepted) => {
    const { error } = await useAPI(
      `/parties/parties/${opportunityId}/participation/`,
      {
        method: "PUT",
        body: {
          accepted,
        },
        onResponseError({ response }) {
          if (!response._data.accepted) {
            throw showError({
              statusCode: response.status,
              statusMessage: response.statusText,
              data: response._data,
            });
          }
        },
      },
    );
    return error;
  };

  return {
    user,
    status,
    refresh,
    doParticipation,
  };
});
